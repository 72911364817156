import { toast } from "react-toastify";

const TOAST_KEY = "persistent-toast";

export const showPersistentToast = (message, options = {}) => {
  const toastData = { message, options };
  localStorage.setItem(TOAST_KEY, JSON.stringify(toastData));

  // If toast is already visible, update it
  if (toast.isActive(TOAST_KEY)) {
    toast.update(TOAST_KEY, { render: message, ...options });
  } else {
    toast.info(message, { ...options, toastId: TOAST_KEY });
  }
};

/**
 * Checks for a persistent toast message stored in localStorage and displays it if found.
 * The toast message is removed from localStorage after being displayed.
 *
 * @function
 * @name checkAndShowPersistentToast
 * @returns {void}
 */
export const checkAndShowPersistentToast = () => {
  const storedToast = localStorage.getItem(TOAST_KEY);
  if (storedToast) {
    const { message, options } = JSON.parse(storedToast);
    toast.info(message, {
      ...options,
      toastId: TOAST_KEY,
      onClose: () => {
        localStorage.removeItem(TOAST_KEY);
        if (options.onClose) options.onClose();
      },
    });
    localStorage.removeItem(TOAST_KEY);
  }
};

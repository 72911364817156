import './normal.css';
import './App.css';
import Home from './pages/home';
import LoginForm from './components/login/LoginForm';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef , useCallback} from 'react';
import LoginCallbackHandler from './components/login/LoginCallbackHandler';
import TermsAndConditions from './components/login/TermsAndConditions';
import LoginErrorPage from './components/login/LoginError';
import { checkAndShowPersistentToast, showPersistentToast } from "./utils/persistentToast";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import config from './config';
import { googleOAuth2 } from "js-utils";

function App() {
	const [currentUser, setCurrentUser] = useState(null);
	const [errorCode, setErrorCode] = useState('');
	const pollingIntervalIdRef = useRef(null);
	const navigate = useNavigate();
	const POLLING_INTERVAL = config.AUTH_POLLING_INTERVAL || 60000;

	const logout = useCallback(async () => {
		try {
		  // Call your logout endpoint if necessary
		  await googleOAuth2.logout("/v0/auth/logout");
		  showPersistentToast("Session expired. Please log in again.", {
			type: "info",
			autoClose: false,
			position: "top-right"
		  });
		} catch (error) {
		  console.error("Error during logout:", error);
		  showPersistentToast("Session expire and logout error.", {
			type: "error",
			autoClose: false,
			position: "top-right"
		  });
		} finally {
		  // Clear user state and navigate to login page
		  setCurrentUser(null);
		  navigate("/home");
		}
	  }, [navigate]);

	  const clearPollingInterval = () => {
		if (pollingIntervalIdRef.current) {
		  clearInterval(pollingIntervalIdRef.current);
		  pollingIntervalIdRef.current = null;
		}
	  };

	  useEffect(() => {
		if (currentUser) {
		  if (!pollingIntervalIdRef.current) {
			pollingIntervalIdRef.current = googleOAuth2.startSessionPolling(
			  "/v0/auth/session-status",
			  POLLING_INTERVAL,
			  logout
			);
		  }
		} else {
		  clearPollingInterval();
		}

		return clearPollingInterval;
	  }, [currentUser, logout, POLLING_INTERVAL]);

	useEffect(() => {
		checkAndShowPersistentToast();
	}, []);

	const RequireAuth = ({ children }) => {
		if (!currentUser) {
			return <Navigate to="/home" />;
		} else if (currentUser && !currentUser['has-accepted-terms']) {
			return <Navigate to="/terms-and-conditions" />;
		} else {
			return children;
		}
	};

	return (
		<div className="App">
			<Routes>
				<Route
					index
					path="/"
					element={
						<RequireAuth>
							<Home currentUser={currentUser} setCurrentUser={setCurrentUser} />
						</RequireAuth>
					}
				/>
				<Route
					path="/home"
					element={<LoginForm setErrorCode={setErrorCode} />}
				/>
				<Route
					path="/login"
					element={
						<LoginCallbackHandler
							setErrorCode={setErrorCode}
							setCurrentUser={setCurrentUser}
						/>
					}
				/>
				<Route
					path="/terms-and-conditions"
					element={
						<TermsAndConditions
							currentUser={currentUser}
							setCurrentUser={setCurrentUser}
						/>
					}
				/>
				<Route
					path="/login-error"
					element={<LoginErrorPage errorCode={errorCode} />}
				/>
			</Routes>
			<ToastContainer />
		</div>
	);
}

export default App;

const config = {
  SLACK_URL: "https://bamboohr.slack.com/archives/C0549BSKHQT",
  REQUEST_FORM_URL: "https://forms.gle/zjkthNu2nnBWy7yT8",
  INTERNAL_POLICY_URL: "https://bamboohr.slite.com/app/docs/2zXZ_z4xuEjpdT",
  EXTERNAL_POLICY_URL:
    "https://www.bamboohr.com/legal/bamboohr-artificial-intelligence-addendum",
  VERSION_ENDPOINT: "v0/",
  USER_PROPERTIES: ["_id", "has-accepted-terms", "metadata"],
  DEFAULT_LLM_CONFIG: {
    opts: {
      model: "gpt-4o",
    },
  },
  DEFAULT_FUNCTION_LABEL: "OpenAI: GPT 4o",
  DEFAULT_FUNCTION_ID: "query-openai-llm",
  QUERY_OPENAI_LLM_FUNCTION_ID: "query-openai-llm",
  QUERY_COHERE_LLM_FUNCTION_ID: "query-cohere-llm",
  ROLLBAR_ACCESS_TOKEN: "15f6ca70b3104801bc412277cc0957a5",
  DEVELOPMENT: process.env.REACT_APP_BHR_ENV === "development",
  AUTH_POLLING_INTERVAL: 60000, // 1 minute
  SUPPORTED_FILE_TYPES: [
    '.pdf', '.602', '.abw', '.cgm', '.cwk',
    '.doc', '.docx', '.docm', '.dot', '.dotm', '.hwp', '.key',
    '.lwp', '.mw', '.mcw', '.pages', '.pbd', '.ppt', '.pptm',
    '.pptx', '.pot', '.potm', '.potx', '.rtf', '.sda', '.sdd',
    '.sdp', '.sdw', '.sgl', '.sti', '.sxi', '.sxw', '.stw',
    '.sxg', '.txt', '.uof', '.uop', '.uot', '.vor', '.wpd',
    '.wps', '.xml', '.zabw', '.epub', '.xlsx', '.xls', '.xlsm',
    '.xlsb', '.xlw', '.csv', '.dif', '.sylk', '.slk', '.prn', '.numbers',
    '.et', '.ods', '.fods', '.uos1', '.uos2', '.dbf', '.wk1', '.wk2',
    '.wk3', '.wk4', '.wks', '.123', '.wq1', '.wq2', '.wb1', '.wb2',
    '.wb3', '.qpw', '.xlr', '.eth', '.tsv'
  ]
};

export default config;

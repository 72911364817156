import React, { useCallback, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useState } from 'react';
import CodeBlock from './codeBlock/CodeBlock';

interface BotResponseProps {
	response: string;
	chatLogRef?: any;
	hasPrinted?: boolean;
	setHasPrinted?: (hasPrinted: boolean) => void;
	sources?: any;
}

const BotResponse = ({
	response,
	chatLogRef,
	hasPrinted,
	setHasPrinted,
	sources,
}: BotResponseProps) => {
	const [botResponse, setBotResponse] = useState('');
	const [isPrinting, setIsPrinting] = useState(true);
	const [isButtonVisible, setIsButtonVisible] = useState(false);
	const [displaySources, setDisplaySources] = useState<JSX.Element | null>(
		null,
	);

	// Render sources as hyperlinks
	const renderSources = useCallback(() => {
		if (sources == null || sources.length === 0) {
			return null;
		}

		return (
			<>
				<div style={{ marginTop: '20px' }}>Sources:</div>
				{sources.map((item, index) => (
					<div key={index} id="statusMessage" ref={chatLogRef}>
						<ul>
							<li>
								{item.source ? (
									<a
										href={item.source}
										target="_blank"
										rel="noopener noreferrer"
									>
										{item.title}
									</a>
								) : (
									// Don't show as clickable URL for null sources
									<p>{item.title}</p>
								)}
							</li>
						</ul>
					</div>
				))}
			</>
		);
	}, [chatLogRef, sources]);

	const stopPrinting = useCallback(() => {
		setIsPrinting((prev) => !prev);
		setDisplaySources(renderSources());
		setTimeout(() => {
			chatLogRef.current?.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
			});
		}, 300);
	}, [chatLogRef, renderSources]);

	useEffect(() => {
		let index = 1;

		let msg = setInterval(() => {
			setIsButtonVisible(true);

			if (!isPrinting) {
				// if isPrinting is false, clear interval and return
				setBotResponse(response);
				setIsButtonVisible(false);
				setDisplaySources(renderSources());
				clearInterval(msg);
				return;
			}
			if (hasPrinted) {
				stopPrinting();
				setIsButtonVisible(false);
				setDisplaySources(renderSources());
				setBotResponse(response);
				setHasPrinted && setHasPrinted(false);
				return;
			}

			setBotResponse(response.slice(0, index));
			if (index >= response.length) {
				clearInterval(msg);
				setIsButtonVisible(false);
			}
			index = index + 10;

			if (chatLogRef !== undefined) {
				chatLogRef.current?.scrollIntoView({
					behavior: 'auto',
					block: 'end',
				});
			}
		}, 50);

		setDisplaySources(renderSources());
		return () => clearInterval(msg); // clear interval on component unmount
	}, [
		chatLogRef,
		response,
		isPrinting,
		renderSources,
		hasPrinted,
		stopPrinting,
		setHasPrinted,
	]);

	return (
		<>
			<ReactMarkdown
				components={{
					code: CodeBlock,
				}}
			>
				{botResponse}
			</ReactMarkdown>
			{isButtonVisible && isPrinting && (
				<button className="stop-message" onClick={stopPrinting}>
					Finish Printing
				</button>
			)}
			{!isButtonVisible && displaySources}
		</>
	);
};

export default BotResponse;

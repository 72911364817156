import "./CodeBlock.css";
import * as React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDarkReasonable } from "react-syntax-highlighter/dist/esm/styles/hljs";

/**
 *  CodeBlock component that displays code blocks with syntax highlighting. The params
 * are expected to be passed by the markdown renderer.
 */
interface CodeBlockProps {
  className?: string;
  children: any;
  inline?: boolean;
}
const CodeBlock = ({ className, children, inline }: CodeBlockProps) => {
  // Code node has a className attribute that contains the language
  const language = className ? className.split("language-")[1] : "plaintext";
  const codeString = children;

  // Renders an inline code block from markdown-like format: `inline code`
  // Otherwise, it returns a code block wrapped with a CopyBar and SyntaxHighlighter components.
  return inline ? (
    <code className="InlineCodeBlock">{codeString}</code>
  ) : (
    <div className="CodeBlockClass">
      <CopyBar code={codeString} language={language} />
      <SyntaxHighlighter
        language={language}
        style={{ ...atomOneDarkReasonable }}
        showLineNumbers
        customStyle={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
          margin: 0,
        }}
      >
        {codeString}
      </SyntaxHighlighter>
    </div>
  );
};

/**
 * CopyBar component that displays the language of the code block and a copy button
 */
interface CopyBarProps {
  code: string;
  language: string;
}

const CopyBar = React.memo(({ code, language }: CopyBarProps) => {
  const [copied, setCopied] = React.useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(code);
    setCopied(true);
  };

  React.useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  return (
    <div className="CopyBar">
      <span className="LanguageLabel">{language}</span>
      <button className="CopyButtonClass" onClick={handleCopy}>
        <div className="CopyButtonContent">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
            fill="none"
            visibility={copied ? "hidden" : "visible"}
          >
            <path
              d="M19.53 8L14 2.47C13.8595 2.32931 13.6688 2.25018 13.47 2.25H11C10.2707 2.25 9.57118 2.53973 9.05546 3.05546C8.53973 3.57118 8.25 4.27065 8.25 5V6.25H7C6.27065 6.25 5.57118 6.53973 5.05546 7.05546C4.53973 7.57118 4.25 8.27065 4.25 9V19C4.25 19.7293 4.53973 20.4288 5.05546 20.9445C5.57118 21.4603 6.27065 21.75 7 21.75H14C14.7293 21.75 15.4288 21.4603 15.9445 20.9445C16.4603 20.4288 16.75 19.7293 16.75 19V17.75H17C17.7293 17.75 18.4288 17.4603 18.9445 16.9445C19.4603 16.4288 19.75 15.7293 19.75 15V8.5C19.7421 8.3116 19.6636 8.13309 19.53 8ZM14.25 4.81L17.19 7.75H14.25V4.81ZM15.25 19C15.25 19.3315 15.1183 19.6495 14.8839 19.8839C14.6495 20.1183 14.3315 20.25 14 20.25H7C6.66848 20.25 6.35054 20.1183 6.11612 19.8839C5.8817 19.6495 5.75 19.3315 5.75 19V9C5.75 8.66848 5.8817 8.35054 6.11612 8.11612C6.35054 7.8817 6.66848 7.75 7 7.75H8.25V15C8.25 15.7293 8.53973 16.4288 9.05546 16.9445C9.57118 17.4603 10.2707 17.75 11 17.75H15.25V19ZM17 16.25H11C10.6685 16.25 10.3505 16.1183 10.1161 15.8839C9.8817 15.6495 9.75 15.3315 9.75 15V5C9.75 4.66848 9.8817 4.35054 10.1161 4.11612C10.3505 3.8817 10.6685 3.75 11 3.75H12.75V8.5C12.7526 8.69811 12.8324 8.88737 12.9725 9.02747C13.1126 9.16756 13.3019 9.24741 13.5 9.25H18.25V15C18.25 15.3315 18.1183 15.6495 17.8839 15.8839C17.6495 16.1183 17.3315 16.25 17 16.25Z"
              fill="#000000"
            />
          </svg>
          <span>{copied ? "Copied!" : "Copy"}</span>
        </div>
      </button>
    </div>
  );
});

export default CodeBlock;

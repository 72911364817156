/**
 * Google OAuth2 implementation.
 */

const randomHexString = function (length) {
  // See: https://stackoverflow.com/a/47496558
  return [...Array(length)].map(() => Math.random().toString(16)[2]).join("");
};

const postJson = function (url, data) {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    cache: "no-cache",
  };

  return fetch(url, options);
};

const AUTH_STATE = "bhr-google-oauth2-state";
const AUTH_COOKIE = "bhr-ai-labs-auth";
const VALID_AUTH_URLS = [
  "https://accounts.google.com/o/oauth2/auth",
  "http://localhost:8080/api/v0/auth/handle-callback",
  "http://localhost:8081/api/v0/auth/handle-callback",
];
const VALID_DOMAIN = "bamboohr.com";

let googleOAuth2 = {
  /**
   * Accepts an authentication URL and returns a Google
   * URL where the client will then request an access token
   * in order to continue the authentication process.
   *
   * As a side-effect, authentication state is written to local storage.
   *
   */
  init: async function (authUrl) {
    const clientKey = randomHexString(64);
    const seconds = 120;
    const milliseconds = 1000;
    const expiration = Date.now() + seconds * milliseconds;
    let authState = { clientKey, expiration };

    localStorage.setItem(AUTH_STATE, JSON.stringify(authState));

    const response = await postJson(authUrl, { state: clientKey });

    if (response.status !== 200) {
      throw `unable to access OAuth2 URL: ${authUrl}`;
    }

    let data = (await response.json()) || {};
    let result = data ? data.url : "";

    if (
      !result.startsWith(VALID_AUTH_URLS[0]) &&
      !result === VALID_AUTH_URLS[1] &&
      !result === VALID_AUTH_URLS[2]
    ) {
      throw `unexpected OAuth2 URL: ${result}`;
    }

    return result;
  },

  /**
   * Accepts a loginUrl and authHashKey. Retrieves a clientKey
   * retrieved from localStorage and verifies that it matches authHashKey.
   * Also accepts optional userProperties used to request additional user attributes.
   * POSTs clientKey and userProperties to loginUrl.
   * NOTE: validateDomain must be set to true unless an application
   * has a comprehensive authorization setup in place
   *
   * If authHashKey and clientKey match then login succeeds and the
   * function returns user email, name, and picture (Google Account avatar)
   * as well as additional fields in userProperties.
   *
   */
  login: async function (loginUrl, authHashKey, userProperties = [], validateDomain = true) {
    const authStateUnparsed = localStorage.getItem(AUTH_STATE);

    if (!authStateUnparsed) {
      throw `${AUTH_STATE} expected in LocalStorage but is missing`;
    } else {
      localStorage.removeItem(AUTH_STATE);
    }

    const authState = JSON.parse(authStateUnparsed);
    const clientKey = authState.clientKey;
    const expiration = new Date(authState.expiration).getTime();

    if (Date.now() - expiration > 0) {
      throw "Authentication timeout";
    }

    if (authHashKey !== clientKey) {
      throw "Auth key mismatch";
    }

    // The back-end will return a JWT token as a cookie if this request is successful
    const payload = { state: clientKey, "user-properties": userProperties };
    const response = await postJson(loginUrl, payload);
    const status = response.status;

    if (status !== 200) {
      console.error("Unable to complete OAuth2 login", { loginUrl, status });
    }

    if (document.cookie.includes(AUTH_COOKIE)) {
      throw `an insecure cookie was sent. ${AUTH_COOKIE} MUST be sent with HttpOnly set`;
    }

    let userinfo;

    if (status == 200) {
      userinfo = await response.json();
    }

    if (validateDomain && userinfo && !userinfo.email.includes(VALID_DOMAIN)) {
      throw `user with an invalid email has attempted to log-in`;
    }

    return { userinfo, status };
  },

  startSessionPolling: function (sessionStatusUrl, checkInterval = 60000, onSessionExpire) {
    const intervalId = setInterval(async () => {
      try {
        const response = await fetch(sessionStatusUrl, { cache: 'no-cache' });
        if (response.status === 401) {
          console.log("Session expired.");
          if (typeof onSessionExpire === 'function') {
            onSessionExpire();
          } else {
            console.error("No callback function provided for session expiration");
          }
        } else if (response.status !== 200) {
          console.error("Unexpected response status from session status URL:", response.status);
        }
      } catch (error) {
        console.error("Error checking session status:", error);
      }
    }, checkInterval);

    return intervalId; // Return interval ID to manage it in the React component
  },

  logout: async function (logoutUrl) {
    let options = {
      cache: "no-cache",
    };

    return fetch(logoutUrl, options);
  },
};

module.exports = googleOAuth2;

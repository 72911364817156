import React from "react";
import BotResponse from "./BotResponse";
import { LlmSelector } from "./LlmSelector";
import FileUploader from "./FileUploader";
import config from "../config";

type IntroSectionProps = {
  handleSubmit: (event: any) => void;
  setNewLlmValue: (value: string) => void;
  setLlmConfig: (config: any) => void;
  setModes: (modes: any) => void;
  setForm: (form: any) => void;
  setInputPrompt: (prompt: string) => void;
  llmId: string;
};

const IntroSection = ({
  handleSubmit,
  setNewLlmValue,
  setLlmConfig,
  setModes,
  setForm,
  setInputPrompt,
  llmId,
}: IntroSectionProps) => {

  return (
    <div className="introContainer">
      <div id="introsection">
        <div className="introImageWrapper">
          <h1>
            <BotResponse response="BambooHR AI Sandbox" />
          </h1>
          <img src="/images/pudgy.png" height="164px" width="164px" alt="pudgy holding a glowing orb of energy"/>
        </div>
        <h2>
          Remember that AI is still learning, and it uses large language models
          which:
          <ul>
            <li>
              {" "}
              Are trained on only public, historical internet and may not be
              current{" "}
            </li>
            <li> May hallucinate "facts" </li>
            <li> May reflect harmful biases </li>
          </ul>
          <span style={{ fontWeight: "bold" }}>Co-pilot, not auto-pilot!</span>{" "}
          You are responsible for evaluating and editing any content you will
          use. Always use AI for good and according to the{" "}
          <a href={config.INTERNAL_POLICY_URL} target="_blank" rel="noreferrer">
            policy.
          </a>
        </h2>
        <h2>
          This sandbox is a work in progress. Any feedback is welcome via{" "}
          <a href={config.SLACK_URL} target="_blank" rel="noreferrer">
            #working-with-ai{" "}
          </a>
        </h2>
        {llmId === "mini-rag" ? (
          <FileUploader handleSubmit={handleSubmit} setForm={setForm} setInputPrompt={setInputPrompt} />
        ) : null}
        <div
          style={{
            marginTop: 18,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          Chat with
          <LlmSelector
            setNewLlmValue={setNewLlmValue}
            setLlmConfig={setLlmConfig}
            setModes={setModes}
          />
        </div>
      </div>
    </div>
  );
};

export default IntroSection;

import api from '../../services/api'; // Adjust the import path as necessary
import { reportError } from '../../utils/rollbar';

export const getChatSessions = async (CHAT_SESSIONS_ENDPOINT) => {
	let chatSessions = [];
	api
		.get(CHAT_SESSIONS_ENDPOINT)
		.then((response) => {
			const newChatSessions = response.data.map((resp) => {
				return {
					name:
						resp['session_name'] === ''
							? 'Blank Chat Name'
							: resp['session_name'],
					id: resp['id'],
					llmId: resp['function_id'],
					llmLabel: resp['llm_label'],
					llmConfig: resp['llm_config'],
					time_created: resp['time_created'],
				};
			});
			chatSessions = newChatSessions;
		})
		.catch((error) => {
			reportError('Error in AI Sandbox: Get chat sessions', error);
		});

	return chatSessions;
};

export function hasCustomLLMConfig(config, llmId) {
	let functionIds = [
		config.QUERY_OPENAI_LLM_FUNCTION_ID,
		config.QUERY_COHERE_LLM_FUNCTION_ID,
	];
	return functionIds.includes(llmId);
}

export function buildChatRequest(chatLog, recentPrompt) {
	const mappedMessages = chatLog.map((item) => {
		return [
			{
				role: 'user',
				content: item.chatPrompt,
			},
			{
				role: 'assistant',
				content: item.botMessage,
			},
		];
	});

	const flatMessages = mappedMessages.flat();

	flatMessages.push({
		role: 'user',
		content: recentPrompt,
	});

	return flatMessages;
}
